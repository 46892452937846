.searchBox {
    /* position: absolute;
    top: 40%;
    left: 80%; */
    /* transform:  translateX(-100%);; */
    border: 1pt solid white;
    height: 35px;
    padding: 0px 3px;
    border-radius: 40px;
    margin-right: 25px;
    /* height: 40px;
    /* border-radius: 40px */ 

}

.searchBox:hover > .searchInput {
    width: 240px;
    padding: 0 6px;
}



.searchButton {
    color: white;
    float: right;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
}

.searchInput {
    border:none;
    background: none;
    outline:none;
    float:left;
    padding: 0;
    color: white;
    font-size: 16px;
    transition: 0.4s;
    line-height: 40px;
    width: 0px;

}
.searchInput::placeholder{
    font-size: 12px;
}
@media screen and (max-width: 620px) {
.searchBox:hover > .searchInput {
    width: 150px;
    padding: 0 6px;
}
}