.ReactFlagsSelect-module_flagsSelect__2pfa2 {
    position: relative !important;
    vertical-align: inherit !important;
    padding-bottom: 5px !important;
    /* text-align: left; */
    width: 18% !important;
    left: 0% !important;
    position: absolute !important;
}


.ReactFlagsSelect-module_selectBtn__19wW7 {
    cursor: pointer !important;
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 5px 10px !important;
    font-family: inherit !important;
    color: #4d4d4d !important;
    border: none !important;
    border-radius: 0px !important;
    background: transparent !important;
}

.ReactFlagsSelect-module_fullWidthOptions__1XeR6 {
    right: 0 !important;
    left: 0 !important;
    max-height: 150px;
}

.form_input {
    /* max-width: 82% !important; */
    margin: auto !important;
    background-color: white !important;
}

.skip_field {
    padding-top: 7px;

}

.skip_field span {
    font-size: 18px;
    color: #1A0DAB;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
}

/* popup */

.react-responsive-modal-modal {
    /* max-width: 800px !important; */
    display: inline-block;
    text-align: center !important;
    vertical-align: middle;
    background: #ffffff;
    box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
    margin: 1.2rem;
    padding: 3.5rem !important;
    position: relative;


}

.thank_you_form button {
    /* width: 100%; */
    padding: 10px;
    margin-top: 15px;
    border-radius: 5px;
    color: white;
}


@media screen and (width: 280px) {
    .form_input {
        max-width: 90% !important;
        margin: auto;
        background-color: white;
    }

    .form_input input {
        padding-left: 30px;
    }

    .ReactFlagsSelect-module_selectBtn__19wW7 {
        width: 50% !important;
        padding: 5px 5px !important;
    }
}

@media (max-width: 600px) {

    .ReactFlagsSelect-module_flagsSelect__2pfa2 {
        position: relative !important;
        vertical-align: inherit !important;
        padding-bottom: 5px !important;
        /* text-align: left; */
        width: 60px !important;
        left: 0% !important;
        position: absolute !important;
    }

    .form_input {
        max-width: 90% !important;
        margin: auto;
        background-color: white;
    }
}

@media (max-width: 991px) {
    .form_input {
        max-width: 75%;
        margin: auto;
        background-color: white;
    }
}


/* reacttags input css */
.custom-react-tagsinput-input {
    border: none !important;
}


.custom-react-tagsinput-input:focus {
    outline: none;
    box-shadow: none !important;
    border: none !important;
    border-color: lightgray !important;

}

.react-tagsinput--focused{
    border-color: lightgray !important;
}

