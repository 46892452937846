.sidenave {
  width: 17rem;
  /* background-color: white !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  border: none; */
}

.sidenavcanvas-container .offcanvas-header,
.sidenavcanvas-container .offcanvas-body {
  background: rgba(0, 0, 0, 0.73);
}

.offcanvas-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  margin-bottom: 10px;
  color: white;
}

.sidenavcanvas-container .offcanvas-header,
.sidenavcanvas-container .offcanvas-body {
  background: rgba(0, 0, 0, 0.73);
  height: auto;
  color: white;
  padding: 3px;
  padding-bottom: 0px !important;
}

.offcanvas-title span {
  color: #e84025;
  font-size: 2rem;
}

.sidenavcanvas-container .offcanvas-body {
  padding: 0;
}

.sidenavcanvas-container .offcanvas-body::-webkit-scrollbar {
  width: 1.3rem;
}

.sidenavcanvas-container .offcanvas-body::-webkit-scrollbar-track {
  background: #e7e7e7;
}

.sidenavcanvas-container .offcanvas-body::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.37);
}

.sidenavcanvas > ul {
  padding-left: 0px !important;
  width: 100%;
  margin-top: 50px;
}

.sidenavcanvas > ul::-webkit-scrollbar {
  display: block;
  width: 5px;
  height: 20px !important;
}
.sidenavcanvas > ul::-webkit-scrollbar-track {
  background: transparent;
}

.sidenavcanvas > ul::-webkit-scrollbar-thumb {
  background-color: #24d29f;
  border-right: none;
  border-left: none;
}

.sidenavcanvas > ul::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 10px;
}

.wrapper::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 10px;
}

.sidenavcanvas > ul li {
  /* padding-left: 5rem; */
  width: 100%;
  padding-top: 30px;
  max-width: 300px;
  margin: auto;
  border-bottom: 1pt solid #32e3b9;

  /* display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: left; */
}

.sidenavcanvas li,
.sidenavcanvas a {
  text-decoration: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: left;
  color: #e0dfdf5f;
}

@media screen and (max-width: 600px) {
  /* #sidebar {
    width: 80%;
  } */

  .profile-icons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
  }
  .shopping {
    margin-left: -30px;
  }

  .title {
    font-size: 16px !important;
    text-align: left;
    color: #e0dfdf5f;
  }

  .sidenavcanvas > ul {
    padding-left: 0px !important;
    width: 100%;
    margin-top: 50px;
    max-height: 400px;
    overflow-y: auto;
  }

  .sidenavcanvas > ul li {
    width: 100%;
    padding-top: 30px !important;
    max-width: 300px !important;
    margin: auto;
    border-bottom: 1pt solid #32e3b9;
  }
}

.sidenavcanvas-container .offcanvas-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 4.2rem;
  color: black;
  margin-top: 1.5rem;
}

.sidenavcanvas ul li ul {
  list-style-type: none;
  display: none;
}

.sidenavcanvas ul li:hover > ul {
  display: block;
}

.sidenavcanvas ul li ul li:hover {
  background: rgba(0, 0, 0, 0.73) !important;
  border-radius: 2px;
  color: white !important;
}
