.canvas-title-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.canvas-title-section img{
    width: 50px;
    cursor: pointer;
}
.canvas-title-section p{
    color: white;
    background: red;
    padding: .8rem 1rem;
    border-radius: 40px;
    font-size: 14px;
    margin: 0;
}
.offcanvas-main-header {
    background-color: #eeeeee;
    height: 164px;
    width: 100%;
    padding: 1.3rem;
   }
.offCanvas-body-section{
    padding: 0 1.2rem;
}
.offCanvas-body-section-row{
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.offCanvas-body-section-row p{
    font-size: 1.2rem;
    color: gray;
}
.radio-check-selector{
    display: none;
}
.offcanvas-header {
    padding: 0;
   }
   .btn.off-close {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    box-shadow: 0px 2px 6px rgba(0px, 2px, 6px, 0.1);
    background-color: #fff;
   }
   .btn.off-close:focus {
    box-shadow: none;
   }
   .salam-text {
    font-size: 12px;
    color: #858484;
   }
   .user-name {
    font-size: 21px;
    font-weight: 500;
   }
   .user-photo {
    width: 113px;
    height: 113px;
    border-radius: 100px;
   }
   .body-content-div {
    margin-top: 50px;
   }
   #user-pf-list > li {
    margin-bottom: 21px;
   }
   .off_text {
    color: #858484;
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 0px;
   }

   .radio-selector {
    border: 1px solid #b5b5b5;
    width: 35px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
   }

   .radio-selector img {
        width: 15px;
        height: 15px;
   }
   input.radio-check-selector {
    width: 0px;
    height: 0px;
    visibility: hidden;
   }
   input.radio-check-selector:checked + label.radio-label .radio-selector {
    border-color: #e84025;
   }
   .slider-div {
    background-color: #eeeeee;
    border: 2px solid #eeeeee;
    padding: 2px 2px 2px 6px;
    border-radius: 23px;
    width: 100px;
    height: 40px;
   }
   .slider-div img{
       width: 30%;
       padding: 5px;
   }
   .switcher {
    border: 2px solid #eeeeee;
    padding: 2px;
    background-color: #fff;
    border-radius: 23px;
    width: 80%;
    padding-left: 4px;
   }
   #sound-witch {
    width: 0px;
    height: 0px;
    visibility: hidden;
   }
   .switcher .form-switch {
    padding-left: 0px;
    margin-bottom: 0px;
   }
   .switcher label.form-check-label {
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out;
    width: 100%;
    padding-left: 10px;
    transform: translateY(4px);
    cursor: pointer;
   }
   .slide-switcher {
    width: 20px;
    height: 20px;
    background-color: #e84025;
    border-radius: 100px;
    transition: 0.15s ease-in-out;
    /* content: url("../images/OFF.svg"); */
    padding: 2px;
   }
   .form-switch
    #sound-witch.form-check-input:checked
    + label.form-check-label
    .slide-switcher {
    background-color: green;
    transform: translateX(35px);
    /* content: url("../images/on.svg"); */
   }
   .btn.offcanvas-btn {
    background-color: grey;
   }
   #audio-controller button.btn:focus {
    box-shadow: none;
   }
   .country-select .form-select {
    border: none;
   }
   .country-select .form-select:focus {
    box-shadow: none;
   }
   button.btn.logout_btn {
    background-color: #fdaf52;
    border-radius: 5px;
    color: #fff;
   }
   button.btn.logout_btn:focus {
    box-shadow: none;
   }
   #settings-ul > li {
    margin-bottom: 12px;
   }
   
   .offcanvas-header-profile{
       display: flex;
       justify-content: space-between;
       align-items: flex-end;
       height: 65%;
       position: relative;
   }
   .offcanvas-header-profile-name p{
    color: gray;
    font-size: 1rem;
    margin: 0;
   }
   .offcanvas-header-profile-name h1{
    font-size: 1rem;
   }
   .offcanvas-header-profile-image img{
       width: 30%;
       position: absolute;
       right: 0;
       top: 30px;
        z-index: 10;
        border-radius: 10px;
   }


   /* .hporx-marquee-container .marquee-container{
       background-color: transparent !important;
   }

   .hporx-marquee-container .marquee-container .overlay{
    --gradient-color: none !important;
    --gradient-width: 200px;
   } */


   