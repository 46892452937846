@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.showcase {
    position: absolute;
    right: 0;
    width: 100%;
    min-height: 100vh;
    padding: 50px 50px 10px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #111;
    transition: 0.5s;
    z-index: 2;
}


.showcase video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.8;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    mix-blend-mode: overlay;
}

.text_wrapper {
    margin: auto;
}

.text {
    position: relative;
    z-index: 10;
    background: #ffffff75;
    padding: 30px 90px;
    text-align: center;

}

.text h2 {
    font-size: 50px;
    font-weight: 600;
    font-family: Poppins;
    letter-spacing: 20px;
    color: black;
    text-shadow: 0px 2px 10px #827c7c;
    line-height: 1em;
    text-transform: uppercase;
}

.text h3 {
    font-size: 36px;
    font-family: Poppins;
    font-weight: 700;
    color: #D6420B;
    text-shadow: 0px 2px 5px #555555;
    line-height: 1em;
    text-transform: uppercase;
}

.text h4 {
    font-size: 14px;
    font-weight: 600;
    font-family: Poppins;
    color: black;
    line-height: 1em;
    padding-top: 10px;
    padding-bottom: 10px;
}

.text h5 {
    font-size: 13.5px;
    font-weight: 500;
    font-family: Poppins;
    color: #D6420B;
    text-shadow: 0px 2px 5px #555555;
    line-height: 1em;
    padding-top: 20px;
}

.text p {
    /* padding: 0px 60px; */
    margin: auto;
    font-size: 1em;
    font-family: Poppins;

    color: black;
    padding: 20px 0px;
    text-align: justify;
    align-items: justify;
    font-weight: 500;
    max-width: 500px !important;
}

.text .truth_block {
    text-align: center;
    font-family: Poppins;

    display: block;
    font-size: 16px;
    font-weight: bold;
    color: black;
    /* text-shadow: 0px 2px 10px #636bca; */
    text-shadow: 0px 2px 5px #555555;



}

.text_link {
    color: #02075D;
    font-size: 18px;
    font-family: Poppins;
    font-weight: bold;
    text-align: left !important;
    align-items: center;


}

.text .link_wrapper {

    padding: 0px 50px;
    align-items: left;
    text-align: left;
}

.source {
    font-size: 8px;
}

.info_text {
    color: black;
    font-size: 10px;
    width: 75%;
    margin: auto;

}


.abstrct_text {
    color: black;
    font-size: 10px;
    font-family: Poppins;
}


.date_small {
    font-size: 9px !important;
}


.wrappers {
    border: 1pt solid;
    max-width: 600px;

}

.company_detail {
    text-align: center;
}

.company_detail h5 {
    text-transform: uppercase;
    color: black;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
}

.company_detail span {
    text-align: center;
}

/* .bottom_block_left span{
    padding-top: 13px !important;
    font-size: 14px;
} */

.bottom_block_left .logo_first {
    display: flex;
    flex-direction: column;
}

.bottom_block_left .logo_first span {
    font-size: 14px;
    text-shadow: 0px 2px 5px #555555;
    font-weight: 700;

}

.bottom_block_right .world_logo {
    display: flex;
    flex-direction: column;
}

.bottom_block_right .world_logo span {
    font-size: 14px;
    margin-top: 12px;
    text-shadow: 0px 2px 5px #555555;
    font-weight: 700;

}

.company_info_name span {
    text-shadow: 0px 2px 5px #555555;


}

.company_detail h5 {
    color: white;
    text-shadow: 0px 2px 5px #555555;



}

.company_detail span {
    color: white;
    text-shadow: 0px 2px 5px #555555;


}

.read-or-hide {
    text-shadow: 0px 2px 5px #555555;


}


@media screen and (width: 280px) {
    .text_wrapper {
        margin: -20px !important;
        width: 130% !important;
    }

    .source {
        font-size: 6px;
    }

    .text h2 {
        font-size: 2em !important;
        font-weight: 600;
        letter-spacing: 20px;
        color: black;
        text-shadow: 0px 2px 10px #827c7c;
        line-height: 1em;
        text-transform: uppercase;
    }

    .text .link_wrapper {
        padding: 0px 10px;
    }

    .text_link {
        letter-spacing: normal;
    }



}

@media screen and (width: 540px) {
    .bottom_block {
        display: flex !important;
        justify-content: space-between !important;
    }

    .source {
        font-size: 8px;
    }

}

@media (max-width: 600px) {

    .showcase,
    .showcase header {
        padding: 10px;
    }



    .source {
        font-size: 6px;
    }

    .bottom_block img {
        width: 80px;
        height: 20px;
    }

    .company_detail h5 {
        text-transform: uppercase;
        color: black;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
    }

    .company_detail span {
        color: black;
        font-size: 12px;
        text-align: center;
    }

    .bottom_block_left {
        display: flex;
        justify-content: center;
        margin-top: 5px;

    }




    .bottom_block_right {
        display: flex;
        justify-content: center;
        margin-top: 5px;
    }

    .bottom_block_center {
        order: -1;

    }



    .text_wrapper {
        margin: -28px !important;
        width: 120%;
    }

    .text {
        width: 100%;
        padding: 30px 0px 10px 0px;
        margin: 0px !important;

        text-align: center;
    }



    .text p {
        padding: 10px 10px !important;
        max-width: 500px !important;
    }

    .bottom_block {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }



}

@media screen and (width: 768px) {
    .showcase {

        padding: 20px 50px 10px 50px !important;

    }

    .source {
        font-size: 8px;
    }

}


@media screen and (width: 820px) {
    .showcase {

        padding: 55px 50px 10px 50px !important;

    }
}





@media (max-width: 991px) {

    .showcase,
    .showcase header {
        padding: 40px;
    }



    .text h2 {
        font-size: 3em;
    }

    .text h3 {
        font-size: 2em;
    }

    .text p {
        /* padding: 0px 60px; */
        margin: auto;
        font-size: 1em;
        color: black;
        padding: 20px 7px !important;
        text-align: justify;
        align-items: center;
        font-weight: 500;
        max-width: 100% !important;
    }

}