.sidebarWrapper {
    position: absolute;
    z-index: 1;
    background: white;
    top: 0;
    height: 500px;
    width: 0px;
    transition: 0.5s;
}

.open_sidebar {
    width: 400px;
}