.cards {
    background: #ffffff;
    box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    margin-bottom: 1.5rem;
    height: 500px;
}

.locator_person_image_container {
    width: 100%;
    height: 300px;
}

.img_section {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    background: #e3e3e3;
    border-radius: 1rem 1rem 0px 0px;
}

.card_data {
    padding: 1.5rem 1.5rem;
}

.card_data h6 {
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.para {
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    color: #828282;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text1 {
    font-size: 1.3rem;
    line-height: 89.5%;
    color: #828282;
    padding-bottom: 1.8rem;
}

.label-locator {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 89.5%;
}

.locator_card_bt {
    width: 100%;
    height: 3.2rem;
    border: 1.2px solid #333333;
    box-sizing: border-box;
    border-radius: 0.7rem;
    background-color: red;
    color: white;
    font-size: 1.2rem;
}

.locator_card_bt:hover {
    background-color: green
}

.icon {
    height: 1.2rem;
    width: 1.2rem;
}

.country_name_text {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100px;
    font-size: 13px;
    display: inline-block;
    white-space: nowrap;
}

.icon_text {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 12px;

}
#input_Select > .select_op > select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url"(data:image/svg+xml;utf8,<svg fill=%27black%27 height=%2730%27 viewBox=%270 0 24 24%27 width=%2730%27 xmlns=%27http://www.w3.org/2000/svg%27><path d=%27M7 10l5 5 5-5z%27/><path d=%27M0 0h24v24H0z%27 fill=%27none%27/></svg>)";
    background-repeat: no-repeat;
    background-position-x: 100%;
    font-size: 16px;
    background-position-y: 15px !important;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: none !important;
    background-repeat: no-repeat;
    background-position-x: 100%;
    font-size: 16px;
}



/*  ================================ RESPONSIVE CSS ================== */

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (max-width: 1200px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media screen and (max-width: 992px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media screen and (max-width: 768px) {}

/* For mobile */
@media screen and (max-width:600px) {}