@media screen and (max-width : 1130px) {
  .nav-content {
    display: none;
  }
}

.nav {
  /* background: linear-gradient(0deg, transparent 120px, white 10px); */
  background: white;
  width: 100%;
  color: black;
  padding: 1rem 0px !important;
  height: 3.5rem !important;
}

.changebackground {
  background-color: transparent;
  width: 100%;
  color: white;
  padding-top: 2rem;
  opacity: 0.8;

}




/* linear-gradient(0deg, transparent 1%, #FFC0CB 00%) */
.hporx-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: all .5s linear;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.hporx-header-skew {
  position: fixed;
  top: 92px;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: all .5s linear;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}


.hporx-header-topbar-skew {
  transform-origin: top;
  transform: scaleY(0);
  opacity: 0;

}



.hporx-header-topbar-skew {
  transform-origin: top;
  transform: scaleY(0);
  opacity: 0;

}

.hporx-header-topbar-normal {
  display: block;

}

.changetopBArbackgournd {
  background-color: gray;
}


.nav .nav-content {
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  /* overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap; */

}

.nav .nav-content::-webkit-scrollbar {
  display: none;
}

.changebackground .nav-content {
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  padding: 0px;
  /* overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap; */
}

.ruby-menu {
  text-align: center;
  width: 100%;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* align-items: center; */
}

.nav-items>.main-li {
  display: inline;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: inherit;
  /* border-bottom: 2px solid transparent; */
  margin-right: 2.5rem;

}

.nav-items>.main-li a {
  padding: 1.5rem 0;
  color: inherit;
  text-decoration: none;
}

.nav-items>.main-li .active {
  display: inline;
  cursor: pointer;
  opacity: 1;
  /* border-bottom: 2px solid #E84025; */
  /* text-decoration: none; */
  color: inherit;
  font-weight: 600;
  text-decoration: underline !important;
  text-decoration-color: #E84025 !important;
  text-underline-offset: 2px;
}

.nav-items>.main-li:hover {
  cursor: pointer;
  opacity: 1;
  /* border-bottom: 2px solid #E84025; */
  text-decoration: underline !important;
  text-decoration-color: #E84025 !important;
  text-underline-offset: 2px;
}


.main-li img {
  width: 10px;
  margin-left: 4px;
}

.section-dark {
  background: #333;
  color: white;
}

.section-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
}




.nav-content ul li .show {
  display: none;
  color: white !important;
  background: rgba(0, 0, 0, 0.73);
  text-decoration: none;
  list-style-type: none;
}

.nav-content ul li:hover > .show {
  display: block; 
  background: rgba(0, 0, 0, 0.73);
  border-radius: 2px;
  width: 60%;
  position: absolute;
  top: 100%;
}

.shows {
  display: none;
}
.nav-content ul li:hover > .shows {
  display: block; 
  background: rgba(0, 0, 0, 0.73);
  border-radius: 2px;
  /* width: ; */
  position: absolute;
  top: 100%;
  padding:10px;
}

 .dropdown-product-link {
  border: none;
  text-decoration: none;
  padding: 1rem;
}

.dropdown-product-link>li {
  padding: 0 1rem !important;
}

.pro:hover , ul {
  display: block;
}





/* ul.ruby-menu > li > ul,ul.ruby-menu > li > ul > li > ul,ul.ruby-menu > li > ul > li > ul > li > ul {
  position: absolute;
  justify-content: left;
  -webkit-box-shadow: 0 8px 24px -5px #ccc;
  -moz-box-shadow: 0 8px 24px -5px #ccc;
  box-shadow: 0 8px 24px -5px #ccc;
}

ul.ruby-menu > li > ul > li,ul.ruby-menu > li > ul > li > ul > li {
  position: relative
}


ul.ruby-menu > li > ul > li > a,ul.ruby-menu > li > ul > li > ul > li > a,ul.ruby-menu > li > ul > li > ul > li > ul > li > a {
  display: flex;
  align-items: center;
  width: 170px;
  line-height: 40px;
  padding: 0 0px 0 20px;
  color: white !important;
  text-decoration: none;
  font-size: 14px
}

ul.ruby-menu > li > ul > li:hover > a,ul.ruby-menu > li > ul > li > a:hover,ul.ruby-menu > li > ul > li > ul > li:hover > a,ul.ruby-menu > li > ul > li > ul > li > a:hover,ul.ruby-menu > li > ul > li > ul > li > ul > li:hover > a,ul.ruby-menu > li > ul > li > ul > li > ul > li > a:hover {
  background: #efefef;
  color: black !important;
}

ul.ruby-menu > li > ul > li > a:only-child:after,ul.ruby-menu > li > ul > li > ul > li > a:only-child:after,ul.ruby-menu > li > ul > li > ul > li > ul > li > a:only-child:after {
  content: ""
}

ul.ruby-menu > li > ul > li > a .fa,ul.ruby-menu > li > ul > li > ul > li > a .fa,ul.ruby-menu > li > ul > li > ul > li > ul > li > a .fa {
  position: relative;
}

ul.ruby-menu > li.ruby-menu-right > ul {
  right: 0
}


ul.ruby-menu > li > ul > li > ul,ul.ruby-menu > li > ul > li > ul > li > ul {
  left: 170px;
  top: -4px;
  z-index: 100
}  */





