.modal.show .modal-dialog {
    transform: none;
    background-color: white;
    border-radius: 10px;

}

.modal-header button {
    font-size: 16px;
    padding: 10px;
    color: red;
}

.modal-title svg {
    float: right;

}

.modal-header button:hover {
    color: red !important;
}

.nameHeading {
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3.6rem;
    color: #242424;
}

.main-image {
    height: 10rem;
    width: 10rem;
    margin-bottom: 2.1rem;
    margin-top: -30px;
}

.card {
    border: none;
}

.flag {
    width: 2.25rem;
    height: 1.4rem;
    margin-top: 1.85rem;
}

.profession {
    font-weight: normal;
    font-size: 1.3rem;
    line-height: 2.4rem;
    color: #828282;
}

.popup_btn {
    height: 40px;
    width: 20%;
    font-size: 20px;

}

.btn-read {
    cursor: pointer;
    padding-top: 5rem;
}


.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;

    background: none !important;
    border: 0;
    border-radius: 0.25rem;
    opacity: .5;
}

.close_btns {
    position: absolute;
    right: 10px;
    font-size: 18px;
    cursor: pointer;
    z-index: 99;
}

.close_btns .close:hover {
    filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
}